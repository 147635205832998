import { EMPTY_CELL_HYPHEN } from "constants/index";

import { Fragment, VFC } from "react";

import { BrokerShipmentSource, ShipperShipmentSource } from "api/rest/shipments/types";
import Text from "components/Text";
import isEmpty from "lodash/isEmpty";

interface RequestsIndexEquipmentCellViewProps {
  item: Pick<ShipperShipmentSource | BrokerShipmentSource, "equipment">;
}

const RequestsIndexEquipmentCellView: VFC<RequestsIndexEquipmentCellViewProps> = ({ item }) => (
  <Text size="small">
    {isEmpty(item.equipment) ? (
      EMPTY_CELL_HYPHEN
    ) : (
      <>
        {item.equipment.map((row, i) => (
          <Fragment key={`${i}_${row}`}>
            {row}
            <br />
          </Fragment>
        ))}
      </>
    )}
  </Text>
);

export default RequestsIndexEquipmentCellView;
