import { FC, useState } from "react";

import { Box, Checkbox, FormControlLabel, portexColor } from "@portex-pro/ui-components";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import Text from "components/Text";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Sentry } from "sentry";

import bidAwardApi, {
  useGetShipperContractQuery,
  useUpdateShipperContractMutation,
} from "../../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { ContractRequestStatus } from "../../types";

type ContractCloseDialogViewProps = PortexDialogProps;

const ContractCloseDialogContainer: FC<ContractCloseDialogViewProps> = ({ ...dialogProps }) => {
  const { t } = useTranslation("shipper");
  const { contractId } = useParams<{ contractId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [sendCloseEmail, setSendCloseEmail] = useState(false);

  const dispatch = useDispatch();
  const [updateContractRequest, { isLoading }] = useUpdateShipperContractMutation();
  const { data: contractRequest } = useGetShipperContractQuery({ urlParams: { contractId } });

  const isClosed = contractRequest?.status === ContractRequestStatus.CLOSED;

  const onClickConfirmClose = async () => {
    try {
      await updateContractRequest({
        contractRequest: { id: Number(contractId), status: ContractRequestStatus.CLOSED },
        sendCloseContractEmail: sendCloseEmail,
      }).unwrap();
      enqueueSnackbar(t("bids.award.actions.close.success"), { variant: "success" });
      dispatch(bidAwardApi.util.invalidateTags(["contracts"]));
      history.push("/shipper/award/");
    } catch (e) {
      enqueueSnackbar(t("bids.award.actions.close.error"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  const onClickConfirmReopen = async () => {
    try {
      await updateContractRequest({
        contractRequest: { id: Number(contractId), status: ContractRequestStatus.ACTIVE },
      }).unwrap();
      enqueueSnackbar(t("bids.award.actions.reopen.success"), { variant: "success" });
      dispatch(bidAwardApi.util.invalidateTags(["contracts"]));
      history.push("/shipper/award/");
    } catch (e) {
      enqueueSnackbar(t("bids.award.actions.reopen.error"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  return (
    <PortexDialog
      {...dialogProps}
      title={isClosed ? t("bids.award.header.actions_reopen") : t("bids.award.header.actions_close")}
      confirmButtonProps={{
        loading: isLoading,
        style: { backgroundColor: portexColor.red500, color: "white" },
      }}
      onClickConfirm={isClosed ? onClickConfirmReopen : onClickConfirmClose}
      loading={isLoading}
    >
      <Box px={5} display="flex" flexDirection="column">
        <Box py={2}>
          <Text size="small">{t(`bids.award.actions.${isClosed ? "reopen" : "close"}.prompt`)}</Text>
        </Box>
        {!isClosed && (
          <Box pl={0.5} pb={2}>
            <FormControlLabel
              className={"Por-confirmation"}
              control={<Checkbox checked={sendCloseEmail} onChange={(_event, checked) => setSendCloseEmail(checked)} />}
              label={t("bids.award.actions.close.checkboxLabel")}
            />
          </Box>
        )}
      </Box>
    </PortexDialog>
  );
};

export default ContractCloseDialogContainer;
