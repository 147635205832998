import { EMPTY_CELL_HYPHEN } from "constants/index";

import { VFC } from "react";

import { Checkbox, portexColor } from "@portex-pro/ui-components";
import { ShipperShipmentSource } from "api/rest/shipments/types";
import OwnerAvatarView from "components/OwnerAvatarView";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import RequestsIndexEquipmentCellView from "components/requests-index/RequestsIndexEquipmentCellView";
import RequestsIndexInfoCellView from "components/requests-index/RequestsIndexInfoCellView";
import RequestsIndexLoadCellView from "components/requests-index/RequestsIndexLoadCellView";
import RequestsIndexModeCellView from "components/requests-index/RequestsIndexModeCellView";
import RequestsIndexStopCellView from "components/requests-index/RequestsIndexStopCellView";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";
import { formatNumber } from "utils/formatNumber";

import ViewRequestButtonContainer from "../../containers/index-page/ViewRequestButtonContainer";

interface RequestsListViewProps {
  items: ShipperShipmentSource[];
  paginationOptions: {
    next: () => void;
    prev: () => void;
    setTake: (take: number) => void;
    take: number;
    page: number;
    total: number;
  };
  variant?: "default" | "bulkEdit";
  headerCheckboxState?: "empty" | "indeterminate" | "allSelected";
  onClickHeaderCheckbox?: () => void;
  isChecked?: (item: ShipperShipmentSource) => boolean;
  onClickRow?: (item: ShipperShipmentSource) => void;
}

const RequestsListView: VFC<RequestsListViewProps> = ({
  items,
  paginationOptions,
  variant = "default",
  isChecked,
  onClickRow,
  headerCheckboxState = "empty",
  onClickHeaderCheckbox,
}) => {
  const { t } = useTranslation("requestManagement");

  const columns: TableViewColumns<typeof items[number]> = [
    {
      name: t("requestList_columns_name_request"),
      renderCell: (item) => <RequestsIndexInfoCellView item={item} />,
      cellProps: { style: { minWidth: 200, verticalAlign: "top", wordWrap: "break-word" } },
    },
    {
      name: t("requestList_columns_name_origin"),
      renderCell: (item) => <RequestsIndexStopCellView item={item} position="origin" />,
      cellProps: { style: { minWidth: 230, verticalAlign: "top" } },
    },
    {
      name: t("requestList_columns_name_destination"),
      renderCell: (item) => <RequestsIndexStopCellView item={item} position="destination" />,
      cellProps: { style: { minWidth: 230, verticalAlign: "top" } },
    },
    {
      name: t("requestList_columns_name_mode"),
      renderCell: (item) => <RequestsIndexModeCellView item={item} />,
      headerCellProps: { width: 80 },
      cellProps: { style: { verticalAlign: "top" } },
    },
    {
      name: t("requestList_columns_name_load"),
      renderCell: (item) => <RequestsIndexLoadCellView item={item} />,
      headerCellProps: { width: 60 },
      cellProps: { style: { verticalAlign: "top" } },
    },
    {
      name: t("requestList_columns_name_equipment"),
      renderCell: (item) => <RequestsIndexEquipmentCellView item={item} />,
      headerCellProps: { width: 150 },
      cellProps: { style: { verticalAlign: "top" } },
      hideCell: variant === "bulkEdit",
    },
    {
      name: t("requestList_columns_name_owner"),
      renderCell: (item) => <OwnerAvatarView user={item.owner} />,
      headerCellProps: { width: 85 },
      hideCell: variant === "bulkEdit",
    },
    {
      name: t("requestList_columns_name_miles"),
      renderCell: (item) => (
        <Text size="small">{item.pricing?.miles ? formatNumber(item.pricing.miles) : EMPTY_CELL_HYPHEN}</Text>
      ),
      headerCellProps: { width: 60 },
      cellProps: { style: { verticalAlign: "top" } },
      hideCell: variant !== "bulkEdit",
    },
    {
      name: t("requestList_columns_name_baseRate"),
      renderCell: (item) => (
        <Text size="small">
          {item.pricing?.baseRatePerLoad ? formatUSD(item.pricing.baseRatePerLoad) : EMPTY_CELL_HYPHEN}
        </Text>
      ),
      headerCellProps: { width: 60 },
      cellProps: { style: { verticalAlign: "top" } },
      hideCell: variant !== "bulkEdit",
    },
    {
      name: t("requestList_columns_name_fuel"),
      renderCell: (item) => (
        <Text size="small">
          {item.pricing?.totalRatePerLoad ? formatUSD(item.pricing.totalRatePerLoad) : EMPTY_CELL_HYPHEN}
        </Text>
      ),
      headerCellProps: { width: 60 },
      cellProps: { style: { verticalAlign: "top" } },
      hideCell: variant !== "bulkEdit",
    },
  ];

  const rows: TableViewRows<typeof items[number]> = {
    headerCellProps: {
      style: {
        backgroundColor: "#fff",
        borderBottom: `1px solid ${portexColor.grey200}`,
        whiteSpace: "nowrap",
      },
    },
    startingIcon: variant === "bulkEdit" ? (item) => <Checkbox checked={isChecked?.(item) ?? false} /> : undefined,
    headerStartingIcon:
      variant === "bulkEdit" ? (
        <Checkbox
          onClick={onClickHeaderCheckbox}
          indeterminate={headerCheckboxState === "indeterminate"}
          checked={headerCheckboxState === "allSelected"}
        />
      ) : undefined,
    startingIconHeaderCellProps: {
      width: "42px",
      style: {
        textAlign: "right",
        padding: 0,
      },
    },
    startingIconCellProps: {
      style: {
        verticalAlign: "top",
        textAlign: "right",
        padding: 0,
        paddingTop: "0.3rem",
      },
    },
    onClickRow,
    endingIcon: variant === "default" ? (item) => <ViewRequestButtonContainer item={item} /> : undefined,
    endingIconCellProps: {
      style: {
        verticalAlign: "top",
        width: "200px",
      },
      width: "200px",
      align: "right",
    },
  };
  return (
    <>
      <TableView items={items} columns={columns} rows={rows} />
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default RequestsListView;
