import { VFC } from "react";

import LoadsFilterBarView from "components/loads/LoadsFilterBarView";
import BrokerShippersFilter from "features/broker-shippers/BrokerShippersFilter";
import { DateTime } from "luxon";

import { useGetBrokerLoads } from "../hooks/useGetBrokerLoads";
import { useSetFilterState } from "../store/brokerLoadsIndexFilterSlice";
import { useBrokerLoadsIndexSliceSelector } from "../store/brokerLoadsIndexStore";

const BrokerLoadsFilterBarContainer: VFC = () => {
  const brokerLoads = useGetBrokerLoads();
  const { searchQuery, shipmentStates, transportationMode, shippersFilter } = useBrokerLoadsIndexSliceSelector(
    (store) => store.brokerLoadsIndexFilterSlice
  );
  const setFilterState = useSetFilterState();
  const lastRefreshedAt = brokerLoads.fulfilledTimeStamp
    ? DateTime.fromMillis(brokerLoads.fulfilledTimeStamp).toLocaleString(DateTime.TIME_WITH_SECONDS)
    : "";

  return (
    <LoadsFilterBarView
      isLoading={brokerLoads.isLoading || brokerLoads.isFetching}
      onChangeFilter={setFilterState}
      onRefresh={brokerLoads.refetch}
      searchQuery={searchQuery}
      shipmentStates={shipmentStates}
      transportationMode={transportationMode}
      lastRefreshedAt={lastRefreshedAt}
      additionalFilters={
        <BrokerShippersFilter
          shippers={shippersFilter}
          onChange={(shippers) => setFilterState({ shippersFilter: shippers })}
        />
      }
    />
  );
};

export default BrokerLoadsFilterBarContainer;
