import { oneMinuteInMilliseconds } from "constants/time/oneMinuteInMilliseconds";

import { QueryArgs, useGetBrokerLoadsQuery } from "api/rest/loads/getBrokerLoadsApi";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";

import { useBrokerLoadsIndexSliceSelector } from "../store/brokerLoadsIndexStore";

const transportationModeMap: Record<TransportationMode, QueryArgs["queryParams"]["mode"]> = {
  ALL: undefined,
  FTL: ["FTL"],
  LTL: ["LTL"],
  DRAYAGE: ["DRAYAGE"],
  TRUCKING: [],
  OCEAN: [],
  AIR: [],
  INTERMODAL: ["INTERMODAL"],
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetBrokerLoadsQueryParams = () => {
  const { searchQuery, shipmentStates, transportationMode, shippersFilter } = useBrokerLoadsIndexSliceSelector(
    (store) => store.brokerLoadsIndexFilterSlice
  );

  const shipperIds = shippersFilter.map((shipper) => shipper.id);

  const queryParams: QueryArgs["queryParams"] = {
    mode: transportationModeMap[transportationMode],
    status: shipmentStates.length ? shipmentStates : undefined,
    search: searchQuery || undefined,
    shipper: !!shipperIds.length ? shipperIds : undefined,
  };

  return omitBy(queryParams, isUndefined);
};

// Ignore this eslint error because we want the inferred return type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetBrokerLoads = () => {
  const queryParams = useGetBrokerLoadsQueryParams();

  return {
    ...useGetBrokerLoadsQuery(
      {
        queryParams: queryParams,
      },
      {
        pollingInterval: 5 * oneMinuteInMilliseconds,
      }
    ),
  };
};
