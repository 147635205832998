import { ReactElement } from "react";

import { useSetBrokerToken } from "app/store/AuthSlice";
import useStaticStores from "hooks/store/useStaticStores";
import useLDFlag from "hooks/useLDFlag";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";

import Loading from "../../../components/Loading";
import { useUserContext } from "../../../hooks/useUserContext";

const AuthenticatedOrUUTRoute = (props: RouteProps): ReactElement => {
  const { isAuthenticated, isLoading: isAuthLoading, user } = useUserContext();
  const { pathname } = useLocation();
  const uut = useStaticStores((store) => store.authSlice.unclaimedUserToken);
  const { brokerToken } = useStaticStores((state) => state.authSlice);
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");

  const setBrokerToken = useSetBrokerToken();

  if (!!brokerToken) {
    // If the user navigates to a `AuthenticatedOrUUTRoute` page that but still has the `brokerToken` value in store,
    // it means they've navigated back to a `uut` enabled page and we need to unset this value in the store so they do not have conflict.
    setBrokerToken(undefined);
  }

  if (isAuthLoading) {
    return <Loading showPortexLogo />;
  }

  if (!isAuthenticated && !uut) {
    return !!enableBrokerAppBar ? (
      <Redirect to={`/broker/signup?returnTo=${pathname}`} />
    ) : (
      <Redirect to={`/?returnTo=${pathname}`} />
    );
  }

  if (isAuthenticated && !uut && !!user?.shipper) {
    return <Redirect to={`/shipper`} />;
  }

  if (!!enableBrokerAppBar && isAuthenticated && !uut && !!user && !user.is_claimed && !user.shipper) {
    return <Redirect to={`/broker/signup?returnTo=${pathname}`} />;
  }

  return <Route {...props} />;
};

export default AuthenticatedOrUUTRoute;
