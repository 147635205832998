import { ReactElement, FC, useCallback, useMemo, useState, VFC } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {
  Layout,
  SvgShipment,
  SvgLane,
  // SvgBenchmarking,
  SvgInsight,
  SvgPartner,
  SvgSetting,
  SidebarNav,
  useLayoutContext,
  Menu,
  Tooltip,
} from "@portex-pro/ui-components";
import { ReactComponent as RequestBidIcon } from "assets/request-bid.svg";
import { ReactComponent as RequestQuoteIcon } from "assets/request-quote.svg";
import { ReactComponent as RequestTenderIcon } from "assets/request-tender.svg";
import NotificationBadge from "components/NotificationBadge";
import Text from "components/Text";
import useLDFlag from "hooks/useLDFlag";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";

import plusIcon from "../../../assets/plus-icon.svg";
import ImpersonationBanner from "../../../components/ImpersonationBanner";
import Main from "../../../components/Main";
import SidebarProfile from "../../../components/SidebarProfile";
import VersionBanner from "../../../components/VersionBanner";
import useShipperSidebarStyles from "../hooks/useShipperSidebarStyles";

const SHIPPER_ROUTE = "/shipper";
const SHIPPER_LAYOUT_SIDEBAR_WIDTH = 200;
const SHIPPER_LAYOUT_SIDEBAR_WIDTH_COLLAPSED = 64;

const SidebarHeader = () => {
  const { t } = useTranslation(["common", "shipper"]);
  const bidsRFP = useLDFlag("bidsRFP");
  const dispatchRequests = useLDFlag("dispatchRequests");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const clearAnchorEl = () => setAnchorEl(null);
  const {
    sidebar: { isCollapsed },
  } = useLayoutContext();
  const styles = useShipperSidebarStyles();

  return (
    <Box px="11px" pb={1}>
      <Tooltip
        title={
          isCollapsed ? (
            <Text size="medium" weight="bold">
              {t("shipper:start")}
            </Text>
          ) : (
            ""
          )
        }
        placement="right"
        arrow
        classes={{ tooltip: styles.tooltip }}
      >
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          variant={"contained"}
          color={"primary"}
          style={{ width: isCollapsed ? "42px" : "100%", height: "42px", minWidth: 0 }}
        >
          {isCollapsed ? <img src={plusIcon} alt="" /> : t("common:start")}
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={clearAnchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Box px={1.5} py={0.5} display="flex" flexDirection="column" gridRowGap={10} width="168px">
          <Button
            size="small"
            startIcon={<RequestQuoteIcon />}
            component={Link}
            to={`${SHIPPER_ROUTE}/requests/create`}
            onClick={clearAnchorEl}
          >
            <Box mr="auto" pl={1}>
              {t("shipper:shipperQuotes.spotQuote")}
            </Box>
          </Button>
          <Button
            size="small"
            startIcon={<RequestBidIcon />}
            disabled={!bidsRFP}
            component={Link}
            to={`${SHIPPER_ROUTE}/bids/create`}
            onClick={clearAnchorEl}
          >
            <Box mr="auto" pl={1}>
              {t("shipper:bids.bid")}
            </Box>
          </Button>
          <Button
            size="small"
            startIcon={<RequestTenderIcon />}
            disabled={!dispatchRequests}
            component={Link}
            to={`${SHIPPER_ROUTE}/dispatches/create/rfp`}
            onClick={clearAnchorEl}
          >
            <Box mr="auto" pl={1}>
              {t("shipper:tender")}
            </Box>
          </Button>
        </Box>
      </Menu>
    </Box>
  );
};

interface ShipperMenuItem {
  label: string;
  icon: ReactElement;
  to: string;
  multipleTo?: string[];
  hide?: boolean;
  Badge?: React.FC;
}

const ShipperSidebar: VFC = () => {
  const history = useHistory();
  const location = useLocation();
  const bidsRFP = useLDFlag("bidsRFP");
  const { t } = useTranslation(["common", "shipper"]);
  const {
    sidebar: { isCollapsed },
  } = useLayoutContext();
  const styles = useShipperSidebarStyles();

  const shipperPages = useMemo<ShipperMenuItem[]>(
    () => [
      {
        label: t("shipper:quotes"),
        icon: <RequestQuoteIcon />,
        to: `${SHIPPER_ROUTE}/requests`,
        multipleTo: [`${SHIPPER_ROUTE}/requests`, `${SHIPPER_ROUTE}/quotes`],
      },
      {
        label: t("common:bids"),
        icon: <RequestBidIcon />,
        to: `${SHIPPER_ROUTE}/award`,
        multipleTo: [`${SHIPPER_ROUTE}/award`, `${SHIPPER_ROUTE}/bids`],
        hide: !bidsRFP,
      },
      {
        label: t("shipper:shipments"),
        icon: <SvgShipment />,
        to: `${SHIPPER_ROUTE}/shipments`,
        Badge: (props) => (
          <NotificationBadge
            {...props}
            BadgeProps={{ style: { width: isCollapsed ? "42px" : "100%", margin: "8px 0px" } }}
            position={{ top: "50%", right: isCollapsed ? undefined : "10%" }}
            identifiers={["shipment", "bolGenerationComplete"]}
          />
        ),
      },
      {
        label: t("shipper:locations"),
        icon: <SvgLane />,
        to: `${SHIPPER_ROUTE}/locations`,
      },
      // {
      //   label: t("shipper:benchmarking"),
      //   icon: <SvgBenchmarking />,
      //   to: `${SHIPPER_ROUTE}/benchmarking`,
      // },
      {
        label: t("shipper:insights"),
        icon: <SvgInsight />,
        to: `${SHIPPER_ROUTE}/insights`,
      },
      {
        label: t("shipper:partners"),
        icon: <SvgPartner />,
        to: `${SHIPPER_ROUTE}/partners`,
      },
      {
        label: t("shipper:settings"),
        icon: <SvgSetting />,
        to: `${SHIPPER_ROUTE}/settings`,
      },
    ],
    [t, bidsRFP, isCollapsed]
  );

  const handleClickLogo = useCallback(() => {
    history.push("/");
  }, [history]);

  const getIsActiveItem = useCallback(
    (item: typeof shipperPages[number]) => {
      return location.pathname.includes(item.to) || item.multipleTo?.some((to) => location.pathname.includes(to));
    },
    [location.pathname]
  );

  return (
    <Layout.Sidebar enableToggle onClickLogo={handleClickLogo} className={styles.tooltip} newIcon>
      <SidebarHeader />

      <SidebarNav className={isCollapsed ? styles.collapsedSidebar : styles.sidebar}>
        {map(shipperPages, (page, i) => {
          if (!!page.hide) return null;

          const Badge = page.Badge ?? ((({ children }) => <>{children}</>) as React.FC);

          return (
            <Badge key={`${i}_${page.label}`}>
              <Tooltip
                key={i}
                title={
                  isCollapsed ? (
                    <Text size="medium" weight="bold">
                      {page.label}
                    </Text>
                  ) : (
                    ""
                  )
                }
                placement="right"
                arrow
                classes={{ tooltip: styles.tooltip }}
              >
                <SidebarNav.Item component={Link} to={page.to} icon={page.icon} selected={getIsActiveItem(page)}>
                  {isCollapsed ? null : page.label}
                </SidebarNav.Item>
              </Tooltip>
            </Badge>
          );
        })}
      </SidebarNav>

      <SidebarProfile />
    </Layout.Sidebar>
  );
};

const ShipperContent: FC = ({ children }) => {
  const {
    sidebar: { isCollapsed },
  } = useLayoutContext();

  return (
    <Main
      style={{
        marginLeft: isCollapsed ? SHIPPER_LAYOUT_SIDEBAR_WIDTH_COLLAPSED : SHIPPER_LAYOUT_SIDEBAR_WIDTH,
        zIndex: 0,
      }}
    >
      {children}
    </Main>
  );
};

const ShipperLayout: FC = ({ children }) => {
  return (
    <Layout.Root
      sidebar={{
        width: SHIPPER_LAYOUT_SIDEBAR_WIDTH,
        collapsedWidth: SHIPPER_LAYOUT_SIDEBAR_WIDTH_COLLAPSED,
      }}
    >
      <ImpersonationBanner />
      <VersionBanner />
      <ShipperSidebar />
      <ShipperContent>{children}</ShipperContent>
    </Layout.Root>
  );
};

export default ShipperLayout;
