import { ComponentProps, FC, useState } from "react";

import { ListItemText, Menu, MenuItem } from "@portex-pro/ui-components";
import useAllLDFlags from "hooks/useAllLDFlags";
import { t } from "i18next";

import { useSetIsAddLanesDialogOpen } from "../store/bidAwardUiSlice";
import { ContractRequestState } from "../types";
import ContractExportCsvMenuItemContainer from "./ContractExportCsvMenuItemContainer";
import ContractCloseDialogContainer from "./dialogs/ContractCloseDialogContainer";
import ContractEditDialogView from "./dialogs/ContractEditDialogView";
import ContractResendDialogView from "./dialogs/ContractResendDialogView";
import ContractViewDialogView from "./dialogs/ContractViewDialogView";
import InvitePartnerDialogContainer from "./dialogs/InvitePartnerDialogContainer";

type ContractHeaderActionsMenuViewProps = {
  anchorEl: ComponentProps<typeof Menu>["anchorEl"];
  onClose: () => void;
  state: ContractRequestState;
};

const ContractHeaderActionsMenuView: FC<ContractHeaderActionsMenuViewProps> = ({ anchorEl, onClose, state }) => {
  const [openDialog, setOpenDialog] = useState("");
  const { enableRFPCloseAction, enableRFPInvitePartners, enableAddLanesToLiveRfp } = useAllLDFlags();
  const isClosed = state === ContractRequestState.CLOSED;
  const setIsAddLanesDialogOpen = useSetIsAddLanesDialogOpen();

  const actions = [
    {
      label: t("bids.award.header.actions_view", { ns: "shipper" }),
      dialog: ContractViewDialogView,
      hide: true,
    },
    {
      label: t("bids.award.header.actions_resend", { ns: "shipper" }),
      dialog: ContractResendDialogView,
      hide: true,
    },
    {
      label: t("bids.award.header.actions_edit", { ns: "shipper" }),
      dialog: ContractEditDialogView,
      disabled: true,
      hide: true,
    },
    {
      label: t("bids.award.header.actions_invitePartners", { ns: "shipper" }),
      dialog: InvitePartnerDialogContainer,
      disabled: isClosed,
      hide: !enableRFPInvitePartners,
    },
    {
      label: t("bids.award.header.actions_close", { ns: "shipper" }),
      dialog: ContractCloseDialogContainer,
      disabled: isClosed,
      hide: !enableRFPCloseAction || isClosed,
    },
    {
      label: t("bids.award.header.actions_reopen", { ns: "shipper" }),
      dialog: ContractCloseDialogContainer,
      disabled: !isClosed,
      hide: !enableRFPCloseAction || !isClosed,
    },
    {
      label: t("bids.award.header.actions_export", { ns: "shipper" }),
      component: ContractExportCsvMenuItemContainer,
    },
  ];

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClose}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: -7, horizontal: 0 }}
      >
        <MenuItem
          onClick={() => {
            onClose();
            setIsAddLanesDialogOpen(true);
          }}
          disabled={!enableAddLanesToLiveRfp || isClosed}
        >
          <ListItemText primary={t("bids.award.header.actions_addLanes", { ns: "shipper" })} />
        </MenuItem>

        {actions.map((action) => {
          if (action.hide) return null;
          const key = `MenuItem - ${action.label}`;

          if (action.component) {
            const Component = action.component;
            return <Component onClose={onClose} key={key} />;
          }

          return (
            <MenuItem
              key={key}
              onClick={() => {
                onClose();
                setOpenDialog(action.label);
              }}
              disabled={action.disabled}
            >
              <ListItemText primary={action.label} />
            </MenuItem>
          );
        })}
      </Menu>
      {actions.map((action) => {
        if (!action.dialog) {
          return null;
        }

        const Dialog = action.dialog;

        return (
          <Dialog key={`Dialog - ${action.label}`} open={openDialog === action.label} onClose={() => setOpenDialog("")}>
            <ListItemText primary={action.label} />
          </Dialog>
        );
      })}
    </>
  );
};

export default ContractHeaderActionsMenuView;
