import { VFC } from "react";

import { FilterListOutlined } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import FilterButtonView from "components/FilterButtonView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { BrokerRequestsIndexFilterState } from "../store/brokerRequestsIndexFilterSlice";

interface BrokerRequestsIndexMoreFiltersViewProps {
  onClickMoreFilters: () => void;

  pickupFromFilter: BrokerRequestsIndexFilterState["pickupFromFilter"];
  deliveryFromFilter: BrokerRequestsIndexFilterState["deliveryFromFilter"];
  onDeletePickupFilter: () => void;
  onDeleteDeliveryFilter: () => void;
}

const BrokerRequestsIndexMoreFiltersView: VFC<BrokerRequestsIndexMoreFiltersViewProps> = ({
  onClickMoreFilters,

  pickupFromFilter,
  deliveryFromFilter,
  onDeletePickupFilter,
  onDeleteDeliveryFilter,
}) => {
  const { t } = useTranslation(["broker"]);

  return (
    <>
      <Button variant="outlined" color="primary" startIcon={<FilterListOutlined />} onClick={onClickMoreFilters}>
        {t("broker:requestsIndexMoreFilters_button")}
      </Button>
      <FilterButtonView
        hidden={!pickupFromFilter}
        content={t("broker:requestsIndexMoreFilters_pickup", {
          date: DateTime.fromISO(pickupFromFilter ?? "").toLocaleString({ month: "short", day: "numeric" }),
        })}
        onDelete={onDeletePickupFilter}
      />
      <FilterButtonView
        hidden={!deliveryFromFilter}
        content={t("broker:requestsIndexMoreFilters_delivery", {
          date: DateTime.fromISO(deliveryFromFilter ?? "").toLocaleString({ month: "short", day: "numeric" }),
        })}
        onDelete={onDeleteDeliveryFilter}
      />
    </>
  );
};

export default BrokerRequestsIndexMoreFiltersView;
