import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BrokerSourceStatus, SourceOrigin } from "api/rest/shipments/types";
import { Shipper } from "api/rest/shippers/types";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import { DateIsoString } from "types/DateIsoString";
import usableActions from "utils/store/usableActions";

export type BrokerRequestsIndexFilterState = {
  transportationMode: TransportationMode;
  searchQuery: string;
  shippersFilter: Shipper[];
  originFilter: SourceOrigin[];
  statusFilter: BrokerSourceStatus[];
  pickupFromFilter: DateIsoString | undefined;
  pickupToFilter: DateIsoString | undefined;
  deliveryFromFilter: DateIsoString | undefined;
  deliveryToFilter: DateIsoString | undefined;
};

const initialState: BrokerRequestsIndexFilterState = {
  transportationMode: TransportationMode.All,
  searchQuery: "",
  shippersFilter: [],
  originFilter: [],
  statusFilter: [],
  pickupFromFilter: undefined,
  pickupToFilter: undefined,
  deliveryFromFilter: undefined,
  deliveryToFilter: undefined,
};

const slice = createSlice({
  name: "brokerRequestsIndexFilterSlice",
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<Partial<BrokerRequestsIndexFilterState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const brokerRequestsIndexFilterActions = usableActions(slice.actions);
export default slice;
