import { VFC } from "react";

import ComingSoon from "components/ComingSoon";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import TableView from "components/TableView";
import useLDFlag from "hooks/useLDFlag";
import noop from "lodash/noop";

import { useGetBrokerSourcesQuery } from "../hooks/useGetBrokerSourcesQuery";
import BrokerRequestsIndexTableView from "../views/BrokerRequestsIndexTableView";

const BrokerRequestsIndexTableContainer: VFC = () => {
  const enableBrokerQuotesPage = useLDFlag("enableBrokerQuotesPage");
  const getBrokerSourcesQuery = useGetBrokerSourcesQuery();

  if (!enableBrokerQuotesPage) {
    return (
      <>
        <ComingSoon hideMessage />
        <TableView tableProps={{ style: { borderCollapse: "separate" } }} columns={[]} items={[]} isLoading={false} />
        <CursorPaginationTableControlsView total={0} page={0} next={noop} prev={noop} setTake={noop} take={25} />
      </>
    );
  }

  return (
    <BrokerRequestsIndexTableView
      items={getBrokerSourcesQuery.data?.data.sources ?? []}
      paginationOptions={getBrokerSourcesQuery.paginationOptions}
      isLoading={getBrokerSourcesQuery.isLoading || getBrokerSourcesQuery.isFetching}
    />
  );
};

export default BrokerRequestsIndexTableContainer;
