import { VFC } from "react";

import { Toolbar, portexColor, AppBar } from "@portex-pro/ui-components";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";
import { useTranslation } from "react-i18next";

const RequestBidAppBarContainer: VFC = () => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids" });
  return (
    <AppBar>
      <Toolbar variant="dense" disableGutters style={{ boxShadow: `0 1px 0 ${portexColor.grey300}`, height: 50 }}>
        <BreadcrumbsContainer>
          <BreadcrumbLink active to={`/shipper/bids/create`}>
            {t("createBid")}
          </BreadcrumbLink>
        </BreadcrumbsContainer>
      </Toolbar>
    </AppBar>
  );
};

export default RequestBidAppBarContainer;
