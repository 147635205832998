import { ReactElement, useEffect, useMemo, useState } from "react";

import { useMutation } from "@apollo/client";
import { Box, Grid, Summary, Tab, Tabs } from "@portex-pro/ui-components";
import { useEnhancedFindFilesForQuoteQuery } from "api/graphql/fileUploads/enhancedFindFilesForQuote";
import { Mode } from "api/graphql/generated";
import { useUpdateQuoteMutation } from "api/rest/quotes/updateQuoteApi";
import ChatContainer from "components/chat/ChatContainer";
import FilesControl from "components/file-uploads/FilesControl";
import useLDFlag from "hooks/useLDFlag";
import filter from "lodash/filter";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ModeEnum } from "types/Mode";

import { Quote, QuoteRequest, QuoteStatus } from "../../../../../api/types/generated-types";
import NotFound404 from "../../../../../components/errors/NotFound404";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import { deserializeNotes } from "../../../../../utils/deserializeNotes";
import getQuoteRequestInfo from "../../../../../utils/getQuoteRequestInfo";
import { serializeNotes } from "../../../../../utils/serializeNotes";
import { BOOK_QUOTE } from "../QuoteDetailsPage";
import { getQuoteInfo } from "../utils/getQuoteInfo";
import QuoteDetails from "./AirReviewBookingBody/QuoteDetails";
import ShipmentDetails from "./AirReviewBookingBody/ShipmentDetails";
import AlertBookedSuccessView from "./components/AlertBookedSuccessView";
import BookNowView from "./components/BookNowView";
import QuoteHistoryView from "./components/QuoteHistoryView";
import QuoteInfoBannerView from "./components/QuoteInfoBannerView";

type AirReviewBookingBodyProps = {
  quoteRequest: QuoteRequest;
  quoteId: string;
  refetchQuoteRequest?: () => Promise<unknown>;
};

enum BOOKING_TABS {
  QUOTE_DETAILS = "quote_details",
  SHIPMENT_DETAILS = "shipment_details",
  QUOTE_HISTORY = "quote_history",
}

const AirReviewBookingBody = ({
  quoteRequest,
  quoteId,
  refetchQuoteRequest,
}: AirReviewBookingBodyProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "AirReviewBookingBody" });
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState<BOOKING_TABS>(BOOKING_TABS.QUOTE_DETAILS);

  const enableChat = useLDFlag("enableChat");
  const enableInternationalQuotesChat = useLDFlag("enableInternationalQuotesChat");

  const [confirmed, setConfirmed] = useState(true);
  const [bookingQuote, setBookingQuote] = useState(false);
  const [bookingNotes, setBookingNotes] = useState<Quote["booking_notes"]>(null);

  const [bookQuote, { loading: bookQuoteLoading }] = useMutation(BOOK_QUOTE, { onError: onApolloError("bookQuote") });
  const [updateQuote] = useUpdateQuoteMutation();

  const allQuotes = useMemo(() => quoteRequest?.air_quotes ?? [], [quoteRequest?.air_quotes]);

  const quote = useMemo(() => {
    return find(allQuotes, ["id", quoteId]);
  }, [allQuotes, quoteId]);

  const { data: files } = useEnhancedFindFilesForQuoteQuery(
    { requestParams: { mode: Mode.Air, quoteId: quote?.id ?? "" } },
    { pollingInterval: 5000, skip: !quote }
  );

  const inactiveQuotes = useMemo(() => {
    return orderBy(
      filter(
        allQuotes,
        (q) =>
          q.status === QuoteStatus.Inactive &&
          q.service_level === quote?.service_level &&
          q.submitter_email === quote?.submitter_email
      ),
      "created_at",
      "desc"
    );
  }, [allQuotes, quote?.service_level, quote?.submitter_email]);

  useEffect(() => {
    if (bookingNotes === null && quote?.booking_notes) {
      setBookingNotes(deserializeNotes(quote.booking_notes));
    }
  }, [bookingNotes, quote?.booking_notes]);

  const handleBookNow = async () => {
    if (!confirmed) {
      enqueueSnackbar(
        "Please review the booking details and confirm that you have read them by selecting the checkbox.",
        { variant: "warning", preventDuplicate: true }
      );
      return;
    }

    try {
      setBookingQuote(true);

      const { data } = await bookQuote({
        variables: {
          input: {
            quote_id: quoteId,
            quote_request_id: quoteRequest.id,
            booking_notes: serializeNotes(bookingNotes),
          },
        },
      });

      if (data?.bookQuote === true) refetchQuoteRequest?.();
    } catch (e) {
      throw e;
    } finally {
      setBookingQuote(false);
    }
  };

  if (!quote) {
    return <NotFound404 />;
  }

  const quoteInfo = getQuoteInfo(quote, quoteRequest);
  const { isExpired, brokerName } = quoteInfo;

  const quoteBooked = quote.status === QuoteStatus.Booked;
  const quoteCanceled = quote.status === QuoteStatus.Canceled;

  const { isQuoteRequestBooked, isQuoteRequestCanceled, isQuoteRequestClosed, closedAt, canceledAt } =
    getQuoteRequestInfo(quoteRequest);

  const disableBookNow =
    quoteBooked ||
    isQuoteRequestBooked ||
    bookingQuote ||
    isQuoteRequestClosed ||
    isQuoteRequestCanceled ||
    bookQuoteLoading;

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid container item md={8} spacing={2}>
          <Grid item xs={12}>
            <QuoteInfoBannerView quoteInfo={quoteInfo} omitFields={["quoteRatePerLoad"]} />
          </Grid>

          {quoteBooked ? (
            <Grid item xs={12}>
              <AlertBookedSuccessView />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Box py={2}>
              <Summary>
                <Summary.Head
                  heading={
                    <Tabs value={currentTab} style={{ margin: "-12px 0" }}>
                      <Tab
                        label={t("common:quoteDetails")}
                        value={BOOKING_TABS.QUOTE_DETAILS}
                        onClick={() => setCurrentTab(BOOKING_TABS.QUOTE_DETAILS)}
                      />
                      <Tab
                        label={t("shipper:shipmentDetails")}
                        value={BOOKING_TABS.SHIPMENT_DETAILS}
                        onClick={() => setCurrentTab(BOOKING_TABS.SHIPMENT_DETAILS)}
                      />
                      <Tab
                        label={"Quote History"}
                        value={BOOKING_TABS.QUOTE_HISTORY}
                        onClick={() => setCurrentTab(BOOKING_TABS.QUOTE_HISTORY)}
                      />
                    </Tabs>
                  }
                />
                {currentTab === BOOKING_TABS.QUOTE_DETAILS ? (
                  <QuoteDetails quoteRequest={quoteRequest} quote={quote} />
                ) : null}
                {currentTab === BOOKING_TABS.SHIPMENT_DETAILS ? (
                  <ShipmentDetails quoteRequest={quoteRequest} quote={quote} />
                ) : null}
                {currentTab === BOOKING_TABS.QUOTE_HISTORY ? (
                  <QuoteHistoryView quote={quote} inactiveQuotes={inactiveQuotes} variant="compact" />
                ) : null}
              </Summary>
            </Box>
          </Grid>
        </Grid>

        <Grid item md={4}>
          <FilesControl
            fileIds={files?.findFilesForQuote?.map((file) => file.id) ?? []}
            dropzoneOptions={{
              disabled: disableBookNow,
            }}
            onMultiUploadSuccess={(results) => {
              return updateQuote({
                urlParams: { quoteId: Number(quote.id) },
                body: { quoteType: ModeEnum.AIR, files: results.map((result) => result.fileId) },
              }).unwrap();
            }}
          >
            <BookNowView
              bookingNotes={bookingNotes ?? quote.booking_notes ?? ""}
              brokerName={brokerName}
              canceledAt={canceledAt}
              closedAt={closedAt}
              confirmed={confirmed}
              disableBookNow={disableBookNow}
              isExpired={isExpired}
              isQuoteRequestBooked={isQuoteRequestBooked}
              isQuoteRequestCanceled={isQuoteRequestCanceled}
              isQuoteRequestClosed={isQuoteRequestClosed}
              onChangeBookingNotes={setBookingNotes}
              onChangeConfirmed={setConfirmed}
              onClickBookNow={handleBookNow}
              quoteBooked={quoteBooked}
              quoteCanceled={quoteCanceled}
              quoteTotal={quote.total_amount ?? 0}
              mode={Mode.Air}
            />
          </FilesControl>
          {enableChat && enableInternationalQuotesChat && !!quote.conversation_id && (
            <Box mt="32px" height={600}>
              <ChatContainer conversationId={quote.conversation_id} maxFiles={4} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AirReviewBookingBody;
