import { VFC } from "react";

import { BrokerShipmentSource, ShipperShipmentSource } from "api/rest/shipments/types";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

interface RequestsIndexLoadCellViewProps {
  item: Pick<ShipperShipmentSource | BrokerShipmentSource, "mode">;
}

const RequestsIndexLoadCellView: VFC<RequestsIndexLoadCellViewProps> = ({ item }) => {
  const { t } = useTranslation(["common"]);
  return <Text size="small">{t(`common:modeEnum.${item.mode}`)}</Text>;
};

export default RequestsIndexLoadCellView;
