import React, { ReactNode, ReactElement, useEffect } from "react";

import { AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useSetBearerToken, useSetIsLoading, useSetPublicToken, useSetUnclaimedUserToken } from "app/store/AuthSlice";
import { useSetBrokerToken } from "app/store/AuthSlice";
import Loading from "components/Loading";
import useStaticStores from "hooks/store/useStaticStores";
import { useHistory } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_REDIRECT_URI,
} from "../../../env";

const AuthConsumer: React.FC = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading: isAuthLoading } = useAuth0();
  const { isLoggingOut, isLoading, unclaimedUserToken, brokerToken } = useStaticStores((state) => state.authSlice);
  const setBearerToken = useSetBearerToken();
  const setIsLoading = useSetIsLoading();
  const [brokerTokenQueryParam] = useQueryParam("brokerToken", StringParam);
  const [uutQueryParam, setUutQueryParam] = useQueryParam("uut", StringParam);
  const [publicToken] = useQueryParam("publicToken", StringParam);
  const setBrokerToken = useSetBrokerToken();
  const setUnclaimedUserToken = useSetUnclaimedUserToken();
  const setPublicToken = useSetPublicToken();

  useEffect(() => {
    if (isLoggingOut) return;

    const getToken = async () => {
      try {
        const token = isAuthenticated ? await getAccessTokenSilently() : "";
        setBearerToken(token);
      } catch (e) {
        console.error("Failed to get authorization access token from auth0!");
      } finally {
        setIsLoading(false);
      }
    };

    if (!!brokerTokenQueryParam) {
      setBrokerToken(brokerTokenQueryParam);
    }

    if (!!uutQueryParam) {
      setUnclaimedUserToken(uutQueryParam);
    } else if (!!unclaimedUserToken && !uutQueryParam) {
      // If the user has a `uut` in the store, but the query param no longer exists,
      // we want to refresh the uut query param. This allows the new broker experience to always have their uut follow them when navigating the broker experience,
      // without having to re-add the `uut` query param to every single new broker page moving forward.
      setUutQueryParam(unclaimedUserToken, "replaceIn");
    }

    if (!!publicToken) {
      setPublicToken(publicToken);
    }

    if (!isAuthLoading) {
      (async () => {
        await getToken();
      })();
    }
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    isAuthLoading,
    isLoggingOut,
    setBearerToken,
    setIsLoading,
    setBrokerToken,
    brokerTokenQueryParam,
    uutQueryParam,
    setUnclaimedUserToken,
    publicToken,
    setPublicToken,
    unclaimedUserToken,
    setUutQueryParam,
    brokerToken,
  ]);

  if (isLoggingOut || isLoading || isAuthLoading) return <Loading showPortexLogo />;

  return <>{children}</>;
};

interface AuthProviderProps {
  children?: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps): ReactElement => {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    if (appState?.returnTo) history.push(appState.returnTo);
  };

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={REACT_APP_AUTH0_REDIRECT_URI}
      audience={REACT_APP_AUTH0_AUDIENCE}
      scope={"read:current_user update:current_user_metadata"}
      cacheLocation={"localstorage"}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthConsumer>{children}</AuthConsumer>
    </Auth0Provider>
  );
};

export default AuthProvider;
