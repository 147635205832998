import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponsePaginatedCursor } from "api/rest/types/responses";
import enhanceWithPagination from "utils/enhanceWithPagination";

import { PaginatedApiRequestQueryArgs, SearchQueryParamsBase } from "../types/requests";
import { Shipper } from "./types";

type QueryParams = SearchQueryParamsBase & {};

type QueryArgs = PaginatedApiRequestQueryArgs<{ queryParams: QueryParams }>;

type ResponseType = ApiResponsePaginatedCursor<{ shippers: Shipper[] }>;

module.hot?.accept();

export const getBrokerShippersApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["SHIPPERS"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getBrokerShippers: builder.query<ResponseType, QueryArgs>({
      query: ({ queryParams }) => ({
        method: "GET",
        url: `broker/shippers`,
        params: queryParams,
      }),
      providesTags: ["SHIPPERS"],
    }),
  }),
});

export const { useGetBrokerShippersQuery, useLazyGetBrokerShippersQuery } = getBrokerShippersApi;

export const {
  useGetBrokerShippersQuery: useGetBrokerShippersQueryPaginated,
  useLazyGetBrokerShippersQuery: useLazyGetBrokerShippersQueryPaginated,
} = enhanceWithPagination(getBrokerShippersApi, "getBrokerShippers", { take: 25 });

export default getBrokerShippersApi;
