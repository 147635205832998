import { VFC } from "react";

import BidReviewPage from "app/pages/bid-review/BidReviewPage";
import BidSubmitPage from "app/pages/bid-submit/BidSubmitPage";
import BrokerBidsIndexPage from "app/pages/broker-bids-index/BrokerBidsIndexPage";
import BrokerInsightsIndexPage from "app/pages/broker-insights-index/BrokerInsightsIndexPage";
import BrokerLoadStatusPage from "app/pages/broker-load-status/BrokerLoadStatusPage";
import BrokerLoadsIndexPage from "app/pages/broker-loads-index/BrokerLoadsIndexPage";
import BrokerQuoteSubmissionPage from "app/pages/broker-quote-submission/BrokerQuoteSubmissionPage";
import BrokerRequestsIndexPage from "app/pages/broker-requests-index/BrokerRequestsIndexPage";
import BrokerShippersIndexPage from "app/pages/broker-shippers-index/BrokerShippersIndexPage";
import BrokerSignupPage from "app/pages/broker-signup-page/BrokerSignupPage";
import DispatchSubmitPage from "app/pages/dispatch-submit/DispatchSubmitPage";
import AuthenticatedOrUUTRoute from "app/routers/components/AuthenticatedOrUUTRoute";
import BrokerAuthenticatedRoute from "app/routers/components/BrokerAuthenticatedRoute";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { withBrokerLayout } from "utils/layout/withBrokerLayout";

import NotFound404 from "../../components/errors/NotFound404";
import QuoteSubmissionPage from "./quote/QuoteSubmissionPage";

const BrokerPages: VFC = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation(["loads", "broker"]);
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");

  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${path}/loads`} />} />
      <Route exact path={`${path}/quote`} render={() => withBrokerLayout(QuoteSubmissionPage)} />
      <Route exact path={`${path}/signup`} render={() => withBrokerLayout(BrokerSignupPage)} />
      <AuthenticatedOrUUTRoute
        exact
        path={[`${path}/requests`, `${path}/quotes`]}
        render={() => withBrokerLayout(BrokerRequestsIndexPage)}
      />
      <AuthenticatedOrUUTRoute exact path={`${path}/bids`} render={() => withBrokerLayout(BrokerBidsIndexPage)} />
      <AuthenticatedOrUUTRoute
        exact
        path={`${path}/shippers`}
        render={() => withBrokerLayout(BrokerShippersIndexPage)}
      />
      <AuthenticatedOrUUTRoute
        exact
        path={`${path}/insights`}
        render={() => withBrokerLayout(BrokerInsightsIndexPage)}
      />
      <Route
        exact
        path={`${path}/requests/quotes/:quoteRequestGuid/submit`}
        render={() => withBrokerLayout(BrokerQuoteSubmissionPage)}
      />
      <BrokerAuthenticatedRoute exact path={`${path}/bids/submit`} render={() => withBrokerLayout(BidSubmitPage)} />
      <BrokerAuthenticatedRoute exact path={`${path}/bids/review`} render={() => withBrokerLayout(BidReviewPage)} />
      <BrokerAuthenticatedRoute
        exact
        path={`${path}/dispatches/submit`}
        render={() => withBrokerLayout(DispatchSubmitPage)}
      />
      <AuthenticatedOrUUTRoute
        exact
        path={[`${path}/loads`, `${path}/shipments`]}
        render={() => withBrokerLayout(BrokerLoadsIndexPage, { title: t("loads:brokerLoadsIndexPage_title") })}
      />
      <AuthenticatedOrUUTRoute
        exact
        path={[`${path}/loads/shipment/:shipmentId/status`, `${path}/shipments/shipment/:shipmentId/status`]}
        render={() => withBrokerLayout(BrokerLoadStatusPage, { title: t("loads:brokerLoadStatusPage_title") })}
      />
      <Route
        path="*"
        render={() => withBrokerLayout(() => <NotFound404 showAppBar={!enableBrokerAppBar} useMarketingUrl />)}
      />
    </Switch>
  );
};

export default BrokerPages;
