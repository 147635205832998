import { VFC } from "react";

import { BrokerShipmentSource, ShipperShipmentSource } from "api/rest/shipments/types";
import ModeIcon from "components/ModeIcon";

interface RequestsIndexModeCellViewProps {
  item: Pick<ShipperShipmentSource | BrokerShipmentSource, "mode">;
}

const RequestsIndexModeCellView: VFC<RequestsIndexModeCellViewProps> = ({ item }) => <ModeIcon mode={item.mode} />;

export default RequestsIndexModeCellView;
