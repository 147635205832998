import { VFC } from "react";

import { useTranslation } from "react-i18next";

import { User } from "../api/types/generated-types";
import BaseUserAvatar from "./BaseUserAvatar";

type OwnerAvatarViewProps = {
  user: Partial<Pick<User, "first_name" | "last_name" | "email">>;
};

const OwnerAvatarView: VFC<OwnerAvatarViewProps> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <BaseUserAvatar
      email={user.email}
      firstName={user.first_name || undefined}
      lastName={user.last_name || undefined}
      subtitle={t("userAvatar_owner")}
    />
  );
};

export default OwnerAvatarView;
