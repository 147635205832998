import { VFC, FC } from "react";

import PortexDialog from "components/PortexDialog";
import SelectDateView from "components/SelectDateView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

const FilterRow: FC<{ name: string }> = ({ name, children }) => {
  return (
    <div className="flex w-full items-center space-y-2">
      <div className="w-1/3">
        <Text size="small" weight="bold">
          {name}
        </Text>
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
};

interface BrokerRequestsIndexMoreFiltersDialogViewProps {
  isOpen: boolean;
  onClose: () => void;
  onChangePickup: ({ from, to }: { from: string | undefined; to: string | undefined }) => void;
  onChangeDelivery: ({ from, to }: { from: string | undefined; to: string | undefined }) => void;
  pickupFrom: string | undefined;
  pickupTo: string | undefined;
  deliveryFrom: string | undefined;
  deliveryTo: string | undefined;
}

const BrokerRequestsIndexMoreFiltersDialogView: VFC<BrokerRequestsIndexMoreFiltersDialogViewProps> = ({
  isOpen,
  onClose,
  onChangePickup,
  onChangeDelivery,
  pickupFrom,
  pickupTo,
  deliveryFrom,
  deliveryTo,
}) => {
  const { t } = useTranslation(["broker"]);

  return (
    <PortexDialog
      open={isOpen}
      onClose={onClose}
      title={t("broker:requestsIndexMoreFiltersDialog_title")}
      confirmButtonCopy={t("broker:requestsIndexMoreFiltersDialog_confirm")}
      onClickConfirm={onClose}
    >
      <div className="flex flex-col my-5 mx-8 space-y-4">
        <FilterRow name={t("broker:requestsIndexMoreFiltersDialog_pickup")}>
          <SelectDateView
            from={pickupFrom}
            to={pickupTo}
            onChange={onChangePickup}
            divProps={{ className: "w-full" }}
          />
        </FilterRow>
        <FilterRow name={t("broker:requestsIndexMoreFiltersDialog_delivery")}>
          <SelectDateView
            from={deliveryFrom}
            to={deliveryTo}
            onChange={onChangeDelivery}
            divProps={{ className: "w-full" }}
          />
        </FilterRow>
      </div>
    </PortexDialog>
  );
};

export default BrokerRequestsIndexMoreFiltersDialogView;
