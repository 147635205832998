import { AppBar } from "@portex-pro/ui-components";
import { useModifyShipperDispatchMutation } from "api/rest/dispatches";
import withAsync from "components/withAsync";
import useEnableIntermodal from "hooks/useEnableIntermodal";
import useLDFlag from "hooks/useLDFlag";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";

import {
  shipmentSourceAdapterSelectors,
  useResetFuelModificationsState,
  useSetFilters,
  useSetPerMileRate,
} from "../../store/requestManagementIndexSlice";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import FuelModificationCollapseView from "../../views/index-page/FuelModificationCollapseView";
import RequestsFilterBarView from "../../views/index-page/RequestsFilterBarView";
import { useRequestsList } from "./hooks/useRequestsList";
import RequestsListFiltersContainer from "./RequestsListFiltersContainer";

const RequestsFilterAppBarContainer = withAsync({
  useHook: useRequestsList,
  Component: ({ loadedData }) => {
    const { isFetching, fulfilledTimeStamp, refetch } = loadedData;
    const {
      modeFilter,
      originFilter,
      statusFilter,
      searchFilter,
      isFuelModificationOpen,
      bulkEditFrom,
      bulkEditTo,
      perMileRate,
      selectedShipmentSource,
    } = useRequestManagementSliceSelector((state) => state.requestManagementIndexSlice);
    const { t } = useTranslation("requestManagement");
    const setFilters = useSetFilters();
    const setPerMileRate = useSetPerMileRate();
    const resetFuelModificationsState = useResetFuelModificationsState();
    const [modifyDispatch, modifyDispatchMutation] = useModifyShipperDispatchMutation();
    const { enqueueSnackbar } = useSnackbar();
    const enableIntermodal = useEnableIntermodal();
    const enableDispatchRequests = useLDFlag("dispatchRequests");
    const enableDrayage = useLDFlag("enableDrayage");

    const lastRefreshedAt = fulfilledTimeStamp
      ? DateTime.fromMillis(fulfilledTimeStamp).toLocaleString(DateTime.TIME_SIMPLE)
      : "";

    const handleUpdateFuel = async () => {
      const dispatchSources = shipmentSourceAdapterSelectors.selectAll(selectedShipmentSource);
      const numberOfTenders = dispatchSources.length;

      try {
        await Promise.all(
          dispatchSources.map(async (source) => {
            if (source.origin !== "DISPATCHES") {
              Sentry.captureException(new Error("Expected the selected sources to all be DISPATCHES"));
              return;
            }

            await modifyDispatch({
              urlParams: {
                requestId: source.origin_id,
              },
              body: {
                fuel_cost: (perMileRate ?? 0) * (source.pricing?.miles ?? 0),
              },
            }).unwrap();
            return;
          })
        );

        resetFuelModificationsState();

        enqueueSnackbar(t("fuelModification_updateFuel_success", { count: numberOfTenders }), {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("fuelModification_updateFuel_error", { count: numberOfTenders }), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
    };

    return (
      <AppBar>
        <RequestsFilterBarView
          filters={<RequestsListFiltersContainer />}
          lastRefreshedAt={lastRefreshedAt}
          onRefresh={refetch}
          isLoading={isFetching}
          mode={modeFilter}
          origin={originFilter}
          status={statusFilter}
          search={searchFilter}
          onChangeMode={(mode) => setFilters({ modeFilter: mode })}
          onChangeOrigin={(origins) => setFilters({ originFilter: origins })}
          onChangeStatus={(statuses) => setFilters({ statusFilter: statuses })}
          onChangeSearch={(search) => setFilters({ searchFilter: search })}
          enableDispatchRequests={!!enableDispatchRequests}
          disableIntermodal={!enableIntermodal}
          disableDrayage={!enableDrayage}
          showDispatchesOnly={isFuelModificationOpen}
        />
        <FuelModificationCollapseView
          open={isFuelModificationOpen}
          perMileRate={perMileRate}
          fromDate={bulkEditFrom}
          toDate={bulkEditTo}
          onChangePerMileRate={setPerMileRate}
          onChangeFrom={(from) => setFilters({ bulkEditFrom: from })}
          onChangeTo={(to) => setFilters({ bulkEditTo: to })}
          onClickConfirm={handleUpdateFuel}
          onClickCancel={() => setFilters({ isFuelModificationOpen: false })}
          disabledConfirmButton={!selectedShipmentSource.ids.length || perMileRate === null}
          isLoading={modifyDispatchMutation.isLoading}
        />
      </AppBar>
    );
  },
});

export default RequestsFilterAppBarContainer;
