import { VFC } from "react";

import { useBoolean } from "usehooks-ts";

import { brokerRequestsIndexFilterActions } from "../store/brokerRequestsIndexFilterSlice";
import { useBrokerRequestsIndexSliceSelector } from "../store/brokerRequestsIndexStore";
import BrokerRequestsIndexMoreFiltersDialogView from "../views/BrokerRequestsIndexMoreFiltersDialogView";
import BrokerRequestsIndexMoreFiltersView from "../views/BrokerRequestsIndexMoreFiltersView";

const BrokerRequestsIndexMoreFiltersContainer: VFC = () => {
  const dialogOpen = useBoolean(false);

  const { pickupFromFilter, pickupToFilter, deliveryFromFilter, deliveryToFilter } =
    useBrokerRequestsIndexSliceSelector((state) => state.brokerRequestsIndexFilterSlice);

  const setFilterState = brokerRequestsIndexFilterActions.useSetFilterState();

  return (
    <>
      <BrokerRequestsIndexMoreFiltersView
        onClickMoreFilters={dialogOpen.setTrue}
        pickupFromFilter={pickupFromFilter}
        deliveryFromFilter={deliveryFromFilter}
        onDeletePickupFilter={() => setFilterState({ pickupFromFilter: undefined, pickupToFilter: undefined })}
        onDeleteDeliveryFilter={() => setFilterState({ deliveryFromFilter: undefined, deliveryToFilter: undefined })}
      />
      <BrokerRequestsIndexMoreFiltersDialogView
        isOpen={dialogOpen.value}
        onClose={dialogOpen.setFalse}
        onChangePickup={({ from, to }) => setFilterState({ pickupFromFilter: from, pickupToFilter: to })}
        onChangeDelivery={({ from, to }) => setFilterState({ deliveryFromFilter: from, deliveryToFilter: to })}
        pickupFrom={pickupFromFilter}
        pickupTo={pickupToFilter}
        deliveryFrom={deliveryFromFilter}
        deliveryTo={deliveryToFilter}
      />
    </>
  );
};

export default BrokerRequestsIndexMoreFiltersContainer;
