import { useGetBrokerSourcesQuery as useGetBrokerSourcesQueryApi } from "api/rest/shipments/getBrokerSources/api";
import { QueryArgs } from "api/rest/shipments/getBrokerSources/types";
import { BrokerSourceDispatchRequestStatus, BrokerSourceQuoteRequestStatus } from "api/rest/shipments/types";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";

import { useBrokerRequestsIndexSliceSelector } from "../store/brokerRequestsIndexStore";

const modeFilterMap: Record<TransportationMode, QueryArgs["queryParams"]["mode"]> = {
  ALL: undefined,
  FTL: ["FTL"],
  LTL: ["LTL"],
  DRAYAGE: ["DRAYAGE"],
  TRUCKING: ["FTL", "LTL", "DRAYAGE"],
  OCEAN: ["FCL"],
  AIR: ["AIR"],
  INTERMODAL: ["INTERMODAL"],
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useQueryParamsForGetBrokerSourcesQuery = () => {
  const {
    searchQuery,
    transportationMode,
    shippersFilter,
    statusFilter,
    pickupFromFilter,
    pickupToFilter,
    deliveryFromFilter,
    deliveryToFilter,
  } = useBrokerRequestsIndexSliceSelector((store) => store.brokerRequestsIndexFilterSlice);

  const shipperIds = shippersFilter.map((shipper) => shipper.id);

  const quoteStatusFilter = statusFilter
    .filter((status) => status.startsWith("QUOTES_"))
    .map((quoteStatus) => quoteStatus.replace("QUOTES_", "") as BrokerSourceQuoteRequestStatus);

  const dispatchStatusFilter = statusFilter
    .filter((status) => status.startsWith("DISPATCHES_"))
    .map((dispatchStatus) => dispatchStatus.replace("DISPATCHES_", "") as BrokerSourceDispatchRequestStatus);

  const queryParams: QueryArgs["queryParams"] = {
    mode: modeFilterMap[transportationMode],
    /** @todo hard-coded quotes for now. Not supporting dispatches until v2 where we also show the bids tab */
    origin: ["QUOTES"],
    quoteRequestStatus: !!statusFilter.length ? quoteStatusFilter : undefined,
    dispatchRequestStatus: !!statusFilter.length ? dispatchStatusFilter : undefined,
    search: searchQuery || undefined,
    pickupFrom: pickupFromFilter,
    pickupTo: pickupToFilter,
    deliveryFrom: deliveryFromFilter,
    deliveryTo: deliveryToFilter,
    shipperIds: !!shipperIds.length ? shipperIds : undefined,
  };

  return omitBy(queryParams, isUndefined);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetBrokerSourcesQuery = () => {
  const queryParams = useQueryParamsForGetBrokerSourcesQuery();

  return {
    ...useGetBrokerSourcesQueryApi({
      queryParams: queryParams,
    }),
  };
};
