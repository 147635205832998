import { VFC } from "react";

import { AppBar } from "@portex-pro/ui-components";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import useEnableIntermodal from "hooks/useEnableIntermodal";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import { useHistory } from "react-router-dom";
import { ModeRfp } from "types/Mode";
import formatOptionalTimestampMilliseconds from "utils/formatOptionalTimestampMilliseconds";
import ContractsListFilterBarView from "views/rfps/ContractsListFilterBarView";
import ContractsListTableView from "views/rfps/ContractsListTableView";

import { useListShipperContractsQuery } from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import { useSetContractListStates, useSetMode, useSetSearch } from "../store/contractListSlice";
import { ContractRequest } from "../types";

const modeFilterMap: Record<TransportationMode, ModeRfp[] | undefined> = {
  ALL: undefined,
  FTL: ["FTL"],
  LTL: undefined,
  DRAYAGE: undefined,
  TRUCKING: ["FTL"],
  OCEAN: undefined,
  AIR: undefined,
  INTERMODAL: ["INTERMODAL"],
};

const ContractsListContainer: VFC = () => {
  const mode = useBidAwardSliceSelector((state) => state.contractListSlice.mode);
  const states = useBidAwardSliceSelector((state) => state.contractListSlice.states);
  const search = useBidAwardSliceSelector((state) => state.contractListSlice.search);
  const setContractListStates = useSetContractListStates();
  const setMode = useSetMode();
  const setSearch = useSetSearch();
  const enableIntermodal = useEnableIntermodal();

  const history = useHistory();

  const { data, isLoading, isFetching, paginationOptions, fulfilledTimeStamp, refetch } = useListShipperContractsQuery({
    queryParams: {
      state: states,
      search,
      mode: modeFilterMap[mode],
    },
    initialPaginationOptions: {
      take: 50,
    },
  });

  const lastRefreshedAt = formatOptionalTimestampMilliseconds(fulfilledTimeStamp);

  const onClickActionButton = (contract: ContractRequest) => {
    history.push(`/shipper/award/${contract.id}`);
  };

  return (
    <>
      <AppBar>
        <ContractsListFilterBarView
          mode={mode}
          onChangeMode={setMode}
          states={states}
          onChangeState={(states) => setContractListStates(states)}
          lastRefreshedAt={lastRefreshedAt}
          onClickRefresh={refetch}
          search={search}
          onSearch={setSearch}
          disableIntermodal={!enableIntermodal}
        />
      </AppBar>
      <ContractsListTableView
        isLoading={isLoading || isFetching}
        contracts={data?.data.contractRequests ?? []}
        onClickActionButton={onClickActionButton}
      />
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default ContractsListContainer;
