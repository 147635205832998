import { useEffect, useState, VFC } from "react";

import { Search } from "@material-ui/icons";
import { useLazyGetBrokerShippersQuery } from "api/rest/shippers/getBrokerShippers";
import { Shipper } from "api/rest/shippers/types";
import DropdownFilterView from "components/DropdownFilterView";
import Loading from "components/Loading";
import SimpleSearchView from "components/SimpleSearchView";
import { useTranslation } from "react-i18next";
import { useDebounce } from "usehooks-ts";

interface BrokerShippersFilterProps {
  shippers: Shipper[];
  onChange: (shippers: Shipper[]) => void;
}

const BrokerShippersFilter: VFC<BrokerShippersFilterProps> = ({ shippers: currentShippers, onChange }) => {
  const { t } = useTranslation(["broker", "common"]);

  const [getBrokerShippers, getBrokerShippersQuery] = useLazyGetBrokerShippersQuery();
  const [search, setSearch] = useState("");
  const debouncedSearchString = useDebounce(search, 200);

  useEffect(() => {
    if (getBrokerShippersQuery.isUninitialized) {
      return;
    }
    (async () => await getBrokerShippers({ queryParams: { search: search || undefined, take: undefined } }).unwrap())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString]);

  const handleOpen = async () => {
    await getBrokerShippers({ queryParams: { search: search || undefined, take: undefined } }).unwrap();
  };

  const loading = getBrokerShippersQuery.isLoading || getBrokerShippersQuery.isFetching;
  const shippers = getBrokerShippersQuery.data?.data.shippers ?? [];

  return (
    <DropdownFilterView
      onOpen={handleOpen}
      muiPopoverProps={{ PaperProps: { style: { maxHeight: "400px" } } }}
      placeholder={t("broker:filterByShipper")}
      selected={currentShippers.map((shipper) => String(shipper.id))}
      options={
        !loading
          ? shippers.map((shipper) => ({
              value: String(shipper.id),
              label: shipper.name,
            }))
          : []
      }
      onChange={(shipperIds) =>
        onChange(
          shipperIds.map((shipperId) => ({
            id: Number(shipperId),
            name: shippers.find((shipper) => shipper.id === Number(shipperId))?.name ?? "",
          }))
        )
      }
      beforeOptions={
        <div className="p-2 sticky top-0 z-10 bg-white border-b space-y-1">
          <SimpleSearchView placeholder={t("broker:searchShippers")} search={search} onChange={setSearch} />
          <div className="flex w-full justify-between">
            <div className="px-1 font-bold">{currentShippers.length} shippers selected</div>
            {!!currentShippers.length && (
              <button className="rounded px-2 font-bold hover:bg-blue-100 text-brandBlue" onClick={() => onChange([])}>
                {t("broker:unselectAll")}
              </button>
            )}
          </div>
        </div>
      }
      afterOptions={
        <>
          {loading && <Loading spinnerOnly height="auto" />}
          {!loading && !shippers.length && (
            <div className="p-6 text-center italic">
              <Search />
              {t("common:pagination.noResult")}
            </div>
          )}
        </>
      }
    />
  );
};

export default BrokerShippersFilter;
