import { VFC } from "react";

import { DateTime } from "luxon";

import { useGetBrokerSourcesQuery } from "../hooks/useGetBrokerSourcesQuery";
import { brokerRequestsIndexFilterActions } from "../store/brokerRequestsIndexFilterSlice";
import { useBrokerRequestsIndexSliceSelector } from "../store/brokerRequestsIndexStore";
import BrokerRequestsFilterBarView from "../views/BrokerRequestsFilterBarView";
import BrokerRequestsIndexMoreFiltersContainer from "./BrokerRequestsIndexMoreFiltersContainer";

const BrokerLoadsFilterBarContainer: VFC = () => {
  const getBrokerSourcesQuery = useGetBrokerSourcesQuery();
  const { searchQuery, transportationMode, shippersFilter, statusFilter } = useBrokerRequestsIndexSliceSelector(
    (store) => store.brokerRequestsIndexFilterSlice
  );
  const setFilterState = brokerRequestsIndexFilterActions.useSetFilterState();
  const lastRefreshedAt = getBrokerSourcesQuery.fulfilledTimeStamp
    ? DateTime.fromMillis(getBrokerSourcesQuery.fulfilledTimeStamp).toLocaleString(DateTime.TIME_WITH_SECONDS)
    : "";

  return (
    <BrokerRequestsFilterBarView
      filters={<BrokerRequestsIndexMoreFiltersContainer />}
      isLoading={getBrokerSourcesQuery.isFetching}
      onChangeFilter={setFilterState}
      onRefresh={getBrokerSourcesQuery.refetch}
      searchQuery={searchQuery}
      statusFilter={statusFilter}
      shippersFilter={shippersFilter}
      transportationMode={transportationMode}
      lastRefreshedAt={lastRefreshedAt}
    />
  );
};

export default BrokerLoadsFilterBarContainer;
