import { VFC } from "react";

import { CustomColor, Status } from "@portex-pro/ui-components";
import { BrokerShipmentSource, BrokerSourceQuoteRequestStatus, ShipperShipmentSource } from "api/rest/shipments/types";
import Text from "components/Text";
import UrgentIconTooltipView from "components/UrgentIconTooltipView";
import StateStatus from "pages/shipper/components/StateStatus";
import { useTranslation } from "react-i18next";
import DispatchStatusView from "views/dispatch-request/DispatchStatusView";
import TenderRequestChipView from "views/dispatch-request/TenderRequestChipView";

interface RequestsIndexInfoCellViewProps {
  item: Pick<
    ShipperShipmentSource | BrokerShipmentSource,
    "origin" | "portex_id" | "reference_number" | "deadline" | "status" | "incoterms"
  >;
  broker?: boolean;
}

const BrokerQuoteStatus: VFC<{ status: string }> = (props) => {
  const status = props.status as BrokerSourceQuoteRequestStatus;
  const { t } = useTranslation(["broker"]);

  const statusColorMap: Record<BrokerSourceQuoteRequestStatus, { color: CustomColor } | undefined> = {
    REQUESTED: { color: "blue" },
    RETURNED: { color: "purple" },
    BOOKED: { color: "green" },
    CLOSED: { color: "red" },
    CANCELED: { color: "red" },
  };
  const value = statusColorMap[status];

  if (!value) {
    return null;
  }
  return (
    <Status light rounded palette={value.color}>
      {t(`broker:quoteRequestStatus_${status}`)}
    </Status>
  );
};

const RequestsIndexInfoCellView: VFC<RequestsIndexInfoCellViewProps> = ({ item, broker = false }) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className="flex flex-col gap-1">
      <div>
        <Text size="small" weight="bold" typographyProps={{ gutterBottom: true }}>
          {item.portex_id}
          <UrgentIconTooltipView deadline={item.deadline ? new Date(item.deadline) : null} />
        </Text>
        <div className="max-w-[200px]">
          <Text size="small" weight="regular">
            {item.reference_number}
          </Text>
        </div>
      </div>

      <div>
        {item.origin === "QUOTES" && !broker && <StateStatus state={item.status} />}
        {item.origin === "QUOTES" && !!broker && <BrokerQuoteStatus status={item.status} />}
        {item.origin === "DISPATCHES" && <DispatchStatusView status={item.status} />}
      </div>

      {item.origin === "DISPATCHES" && (
        <div>
          <TenderRequestChipView />
        </div>
      )}

      {item.incoterms && (
        <div>
          <Status light rounded palette="grey" rightText={item.incoterms.short}>
            {t("common:incoterms")}
          </Status>
        </div>
      )}
    </div>
  );
};

export default RequestsIndexInfoCellView;
